<template>
  <div class="subprestation-name">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.OscarSubTaskName"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title">
          <breadcrumb
            location="/oscar-subtask-names"
            text="Sous-tâche OSCAR"
          ></breadcrumb>
          {{ oscarSubTaskName.taskName }} - {{ oscarSubTaskName.subtaskName }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <span>Informations</span>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns">
            <div class="column is-half" v-if="oscarSubTaskName">
              <text-field
                label="Nom tâche"
                v-model="oscarSubTaskName.taskName"
                required
              ></text-field>
              <text-field
                label="Nom sous-tâche"
                v-model="oscarSubTaskName.subtaskName"
                required
              ></text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "oscar-subtask-name",
  props: ["id"],
  computed: {
    ...mapGetters({
      editMode: "App/isEdition",
    }),
  },
  data() {
    return {
      oscarSubTaskName: {},
    };
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      axios.get(`/oscar-subtask-names/${this.id}`).then((r) => {
        this.oscarSubTaskName = r.data;
        this.$store.dispatch("App/setEditing", false);
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/oscar-subtask-names/${this.id}`).then((res) => {
            if (res.data.success) {
              this.$router.replace("/oscar-subtask-names");
            }
          })
      );
    },
    save() {
      return axios
        .put(`/oscar-subtask-names/${this.id}`, this.oscarSubTaskName)
        .then((response) => {
          if (response.data.success) {
            this.fetchData();
          }
        });
    },
    edit() {
      this.$store.dispatch("App/setEditing", true);
    },
    cancel() {
      this.fetchData();
    },
  },
};
</script>

<style lang='scss' scoped>
</style>